// Dependencies.
@import 'navigation/navbar/navbar';

// Warning
//
// Warning adds the warning icon to the element as CSS content or as a 
// background image 
//
// If applied to the `details` element it also displays the information across
// the entire screen and alternates background color on paragraphs
//
// Markup: warning.twig
//
// Style guide: components.warning

%warning {
  &::before {
    content: $warning;
    display: inline-block;
    line-height: 1;
    margin-right: 1em;
    vertical-align: text-top;
  }
}

// This works best on left-aligned text
%warning-background {
  background-image: $warning;
  background-repeat: no-repeat;
  background-position: 0 50%;
  background-size: auto 1em;
  padding-left: 2em;
  text-align: left;
}

// Napomena 
%warning-background-napomena {
  @extend %warning-background;
  background-position: 1em 50%;
  border: 1px solid color('napomena');
  border-radius: .3em;
  display: table; // fit width to content
  margin: $margin-vertical-default auto;
  padding: $margin-vertical-default/2 $gutter-main;
  padding-left: 3em;

  &::before {
    color: color('napomena');
    content: 'Napomena: ';
    display: block;
  }
}

$summary-padding-vertical: 1rem;
$summary-vertical: $base-line-height*$base-font-size + 2*$summary-padding-vertical;

// currently on details HTML element, requires polyfill for IE
.warning {
  position: relative; // context for children

  summary {
    @extend %warning;
    color: color('warning-summary');
    cursor: pointer;
    font-weight: bold;
    padding: $summary-padding-vertical 2vw;

    &::-webkit-details-marker {
      display: none;
    }
  }

  &[open] {
    @supports (position: fixed) {
      padding-top: $summary-vertical;
      @include respond-to('l') {
        padding-top: 0;
      }
    }
  }

  &[open],
  &[open] > summary {
    @supports (position: fixed) {
      left: 0;
      min-height: min-content;
      position: fixed;
      top: 0;
      width: 100vw;
      z-index: 22;
    }
    @include respond-to('l') {
      min-height: 0;
      position: static;
      width: auto;
      z-index: 1;
    }
  }

  &[open] > summary {
    background-color: color('warning-summary');
    color: color('white');
    position: relative; // clear header
    text-align: left;
    @include respond-to('l') {
      text-align: center;
    }

    &::after {
      @extend %navbar-button;
      content: '\00D7';
      display: block;
      line-height: 1;
      position: absolute;
      right: 2vw;
      text-align: center;
      top: .2em;
      width: 1em;
      @include respond-to('l') {
        display: none;
      }
    }
  }
}

.warning-content {
  background-color: color('white');
  box-shadow: 1em 1em 3em color('shadow');
  @include respond-to('l') {
    bottom: $summary-vertical + 1rem;
    left: 50%;
    max-width: 42em;
    position: absolute;
    top: auto;
    transform: translateX(-50%);
    z-index: 18;
  }

  & > p {
    margin: 0;
    padding: $margin-vertical-default 3vw;

    &:nth-child(2n) {
      background-color: color('warning-p-bg');
      border: 1px solid color('border');
      border-width: 1px 0;
      @include respond-to('l') {
        background-color: color('warning-p-bg-desktop');
      }
    }
  }
}
