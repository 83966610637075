// sass-lint:disable indentation, no-color-keywords

// Colors
//
// Use the `color()` function to add colors to CSS properties. To learn more,
// [read the Chroma documentation](http://johnalbin.github.io/chroma/).
//
// ### How to use
// The concept of "palette/branding" and "functional" color names ensures the
// easiest and most intuitive color handling in a project. 
//
// There are always **2 color schemes active**. 
// 
// One is usually called 
// **branding** and it containes color definitions with **descriptive** names
// of all the unique colors used in the design, a palette, one entry for each
// color. 
// 
// The other color scheme is usually called **functional** and it contains as
// many entries as there are differently colored elements in the design. Each
// "functional" entry is defined as using one of the "branding" colors from 
// the palette. 
//  
// Palette color scheme names (from "branding") only exist in the 
// `_colors.scss` document. 
// 
// You can add as many "palette" color schemes as you wish. For example if a 
// site goes through a rebranding procedure, the old color scheme will be kept
// in one color scheme, while the new palette will be added in a new scheme. 
//
// "Functional" scheme is then updated to only use the colors defined in the 
// new scheme. We can keep the old palette for reference, but only one 
// "branding" palette is being actively used. 
// 
// Only ever **use "functional" color names in code!** This way when the design
// team sends you a color change request you only need to update it in one 
// place, inside the "functional" palette! No need for scouring all project
// files for any instances where it's being used so it can be replaced!
//
// 
// Markup: chroma.twig
//
// Weight: -1
//
// Style guide: sass.colors

// Define the default color scheme's color names.
$chroma: define-default-color-scheme('branding', 'The site\'s main colors. Can be used to define colors in other color schemes.');

$chroma: add-colors((
  min2go-black:            #2c313c,
  min2go-blue:             #57a4ff,
  min2go-lightblue:        #f5f9ff,
  min2go-lightblue-bg:     #f6faff,
  min2go-red:              #ff6e6e,
  min2go-green:            #8dd374,
  min2go-greenLight:       #ebffe3,
  min2go-orange:           #fac02d,
  min2go-gray:             #878a90,
  min2go-lightgrey:        #d6d6d6,
  min2go-extralightgrey:   #f3f4f5,
  min2go-greybg:           #f7f7f7,
  min2go-navy:             #1b3e69,
  min2go-navy26opaque:     #e7ebef, // #1b3e691a, Alpha 26/255
  min2go-navy65opaque:     #c4cdd8, // #1b3e6941, Alpha 65/255


  black:                  #000,
  grey-dark:              ('black' lighten 40%), // #666
  'grey':                 ('black' lighten 60%), // #999
  grey-light:             ('black' lighten 80%), // #ccc
  grey-extra-light:       ('black' lighten 93.33%), // #eee
  white:                  #fff,
  blue:                   #0072b9,
  red:                    #c00,
  yellow:                 #fd0,
  green:                  #080,
));

// Define color names for functional uses.
$chroma: define-color-scheme('functional', 'Colors used by functional parts of the design.');
$chroma: add-colors('functional', (
  // Colors used in the main content area.
  text:                   'min2go-gray',
  text-bg:                'white',
  body-bg:                'text-bg',

  skip-link:              'white',
  skip-link-bg:           'grey-dark',

  selection:              'white',
  selection-bg:           ('min2go-blue' darken 20%),

  target:                 'min2go-blue',
  target-bg:              ('min2go-blue' lighten 80%),

  heading:                'min2go-black',

  link:                   'min2go-blue',
  link-visited:           ('min2go-blue' darken 20%),
  link-hover:             'min2go-blue',
  link-active:            'min2go-red',

  border:                 'min2go-extralightgrey',
  shadow:                 'min2go-lightgrey',

  navbar-mobile-bg:       'white',
  nav-hamburger-bg:       'min2go-blue',
  nav-hamburger:          'white',
  nav-prijava:            'min2go-black',
  nav-registracija:       'min2go-blue',
  nav-current:            'min2go-blue',

  trazilica-rezultati:    'min2go-lightblue',
  karta-bg:               'min2go-lightgrey',

  kvadrati-uzorak:        ('min2go-blue'),

  form-accent:            'min2go-blue',
  // mix(color('text'), color('white'), 20%)    // #ccc
  input-color:            'min2go-black',
  input-noaction-color:   'grey-light',
  input-valid:            'min2go-green',
  input-invalid:          'min2go-red',
  placeholder:            'input-noaction-color',
  form-item-separator:    ('min2go-black' lighten 72%),
  select-icon:            'min2go-blue',
  select-icon-desktop:    'min2go-gray',

  autocomplete:           'text',
  autocomplete-bg:        'text-bg',
  autocomplete-select:    'white',
  autocomplete-select-bg: 'blue',

  button:                 'white',
  button-bg:              'form-accent',
  button-hollow:          'min2go-blue',
  button-hollow-bg:       'min2go-lightblue',
  button-selecteditem:    'min2go-blue',
  button-disabled:        'grey',
  button-disabled-bg:     'min2go-greybg',

  warning-summary:        'form-accent',
  warning-p-bg:           'min2go-lightblue-bg',
  warning-p-bg-desktop:   'min2go-greybg',

  trazi-opcije:           'min2go-gray',
  trazi-opcije-desktop:   'text',
  otvori-filtre:          'min2go-blue',
  otvori-filtre-desktop:  'min2go-gray',
  filter-heading:         'min2go-blue',
  filter-heading-bg:      'min2go-greybg',
  filter-heading-desktop: 'min2go-black',


  // Projekt/Kontakt
  projekt:                'min2go-black',
  projekt-brojke:         'min2go-lightblue',
  projekt-brojke-dd:      'min2go-blue',



  fieldset-summary:       'grey',
  form-error:             'red',

  filter-tabs-bg:         'min2go-lightblue-bg', // or #f5f9ff?
  filter-tabs:            'min2go-gray',
  filter-tabs-current:    'min2go-blue',
  filter-tabs-hover:      'min2go-blue',
  filter-tabs-desktop-current: 'min2go-blue',
  filter-tabs-content:    'min2go-black',
  filter-tabs-focus:      'min2go-blue',
  filter-tabs-disabled:   'min2go-lightgrey',
  filter-tabs-checked:    'min2go-blue',
  filter-tabs-checked-bg: 'min2go-lightblue',
  filter-reset:           'min2go-black',
  filter-primijeni:       'min2go-blue',

  mark:                   'min2go-green',
  mark-zatvoreno:         'min2go-red',

  postaja-cijene:         'min2go-black',
  postaja-cijena-b7:      'min2go-black',
  postaja-cijena-e5:      'min2go-green',
  postaja-cijena-h2:      'min2go-orange',
  postaja-link:           'min2go-blue',
  postaja-shadow:         'min2go-navy26opaque',
  postaja-shadow-hover:   'min2go-navy65opaque',
  postaja-opcije-bg:      'min2go-lightblue',
  postaja-opcije:         'min2go-black',

  otvorene-heading:       'min2go-blue',
  otvorene-heading-bg:    'min2go-greybg',

  postaja-chartist:       'text',
  postaja-chartist-grid:  'min2go-lightgrey',
  postaja-chartist-legend: 'min2go-black',

  napomena:               'min2go-blue',

  obveznici-bg:           'min2go-lightblue',
  choices:                'min2go-black',
  choices-selected:       'button-selecteditem',
  choices-selected-highlighted: 'min2go-green',
  choices-selectable-highlighted: 'min2go-lightblue',
  choices-selected-area:  'min2go-greybg',












  mark-highlight:         'red',
  mark-bg:                'yellow',

  menu-active:            'text',

  preview-bg:             ('yellow' lighten 43%),

  progress-bar:           'blue',
  progress-bar-bg:        'grey-light',
  progress-bar-border:    'grey-dark',

  resizable-grippie:      'grey-extra-light',

  row-stripe:             'grey-extra-light',
  row-disabled:           'grey-light',

  status:                 'blue',
  status-bg:              (status lighten 62%),
  status-highlight:       text,
  warning:                text,
  warning-bg:             ('yellow' lighten 45%),
  warning-border:         'yellow',
  error:                  'red',
  error-bg:               (error lighten 57%),

  tabs-border:            'border',
  tabs-bg:                'text-bg',
  tab:                    'text',
  tab-text-shadow:        'white',
  tab-bg:                 ('grey-light' lighten 6.66%),
  tab-secondary:          'grey-dark',

  table-drag:             ('yellow' lighten 43%),
  table-select:           ('yellow' lighten 47%),

  watermark:              'grey-extra-light',
));

$chroma-active-scheme: 'functional';
