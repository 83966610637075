// Postaja
//
// Base styles for the components showing one station info
//
// Markup: postaja.twig
//
// Style guide: components.postaja

$padding-horizontal: 4vw;
$padding-vertical: $padding-horizontal;

.postaja,
%postaja {
  margin: $margin-vertical-default .2em;
  max-width: 30em;
  // keeps them same width across rows
  flex: 1 1 0;

  &-link {
    background-color: color('white');
    box-shadow: .1em .1em .5em color('postaja-shadow');
    color: inherit; // undo default anchor color
    display: block;
    padding: $padding-vertical $padding-horizontal 0;
    text-align: left;
    transform: translateY(0) translateZ(0);
    transition: transform .6s ease-out 0s;
    // keep equal height and push bottom part so borders always align
    display: flex;
    flex-flow: column nowrap;
    height: 100%;

    &:hover {
      box-shadow: 1em 1em 3em color('postaja-shadow-hover');
      text-decoration: none;
      transform: translateY(-1em) translateZ(0);

      // non hover styles are the bottom of this rule around line 140
      [aria-hidden='true'] {
        background-color: color('postaja-link');
        color: color('white');
        text-decoration: none;
        @include respond-to('l') {
          &::after {
            content: $right-arrow-white;
          }
        }
      }
    }
  }

  h3 {
    font-size: (22rem / 16);
    // push address down so it doesn't cover the absolute h3
    margin: 2rem 0 1rem;
  }

  // Otvoreno / Zatvoreno
  &-rv {
    margin: 0;
    position: absolute;
    left: $padding-horizontal;
    top: $padding-vertical;

    dt {
      @include color-mark();
      display: inline;
      font-size: (11rem / 16);
    }

    dd {
      display: inline;
      font-size: (10rem / 16);
      margin: 0 0 0 .4em;
    }

    &[data-postaja-rv='Zatvoreno'] {
      dt {
        @include color-mark(color('mark-zatvoreno'));
      }
    }
  }

  &-lokacija {
    font-style: normal; // override address
    line-height: 1.3;

    ul {
      list-style: none;
      margin: 0 0 $margin-vertical-default;
      padding: 0;
    }

    li {
      background-image: $pin;
      background-position: left center;
      background-repeat: no-repeat;
      margin-bottom: .6em;
      padding-left: 2em;
    }
  }

  &-lokacija &-proximity {
    background-image: $distance;
  }

  &-cijene {
    border-bottom: 1px solid color('border');
    border-top: 1px solid color('border');
    color: color('postaja-cijene');
    font-size: (18rem / 16);
    font-weight: bold;
    list-style: none;
    // top one auto to push to bottom in flex
    margin: auto -#{$padding-horizontal} 0;
    padding: 1.4em 0;
    text-align: center;
    display: flex;
    flex-flow: row nowrap;

    [data-postaja-gorivo] {
      margin: 0 auto;
      padding: 0 2vw;
      position: relative; // context for h2 absolute ::after
      flex: 1 0 auto;

      &:not(:first-of-type) {
        border-left: 1px solid color('border');
      }

      &::before {
        color: color('white');
        content: attr(data-postaja-gorivo);
        display: block;
        font-size: (14rem / 16);
        margin: 0 auto .5em;
        padding: .4em .4em .3em;
        text-transform: uppercase;
        width: 1.7em;
      }
    }
  }

  &-cijena-unit {
    font-size: (14rem / 16);
    font-weight: normal;
  }

  // what looks like Details link
  [aria-hidden='true'] {
    background-color: color('white');
    color: color('postaja-link');
    display: block;
    font-family: $roboto-condensed;
    font-weight: bold;
    margin: 0 -#{$padding-horizontal};
    padding: 1em $padding-horizontal;
    text-align: center;
    transition: background-color .6s ease-out 0s, color .6s ease-out 0s;
    @include respond-to('l') {
      text-align: right;

      &::after {
        color: inherit;
        content: $right-arrow-blue;
        margin-left: .5em;
      }
    }
  }
}

[data-postaja-gorivo='e5']::before {
  background-color: color('postaja-cijena-e5');
  border-radius: 100%;
}

[data-postaja-gorivo='b7']::before {
  background-color: color('postaja-cijena-b7');
  border-radius: .3em;
}

[data-postaja-gorivo='h2'] {
  &::before {
    background-color: color('postaja-cijena-h2');
    border-radius: .3em;
    content: '\00a0\00a0' !important; // 2 unbreakable space chars
    transform: rotateZ(45deg);
  }

  &::after {
    color: color('white');
    content: attr(data-postaja-gorivo);
    display: block;
    font-size: (14rem / 16);
    margin: 0 auto .5em;
    padding: .4em .4em .3em;
    text-transform: uppercase;
    width: 1.7em;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

// Postaja u vertikalnom popisu
@mixin postaja-compact-vertical() {
  max-width: 50em;
  margin: 1em auto;

  .postaja-link {
    // $trazi-padding: $margin-vertical-default;
    padding: $margin-vertical-default;
  }

  h3 {
    margin-top: 0;
    // allow for absolute radno vrijeme
    padding-right: 10em;
  }

  .postaja-rv {
    left: auto;
    // $trazi-padding: $margin-vertical-default;
    right: $margin-vertical-default;
  }

  .postaja-lokacija {
    li {
      display: inline;
      margin-right: 1em;
    }
  }

  .postaja-cijene {
    border-width: 0;
    display: block; // override earlier flex
    margin: 0; // override flex pushing down
    padding: 0;
    text-align: left;

    li {
      border-width: 0 !important;
      display: inline-block;
      margin-right: 1.2em;
      padding: 0;

      &::before {
        display: inline-block;
        margin: 0 .6em 0 0;
        text-align: center;
      }
    }

    [data-postaja-gorivo='h2']::after {
      display: inline-block;
      left: 1.3em;
      margin: 0 .8em 0 0;
      text-align: center;
    }
  }
}
