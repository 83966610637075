// Choices
//
// Changes made to styles using the Choices jsvascript library for more `<select>`
// element functionality
// The file `_choices.scss` is the original CSS (not Sass) that came with the 
// library and is necessary for all the library functionality to function properly.
// It isn't included in the CSS output, it is only here as a future reference, in 
// case we need to use more functions from that library. 
//
// This file contains styles for the `<select>` element multichoice, with remove
// buttons on each item, and a search field. 
//
// Markup: choices.twig
//
// Style guide: components.choices-custom


%choices,
.choices {
  color: color('choices');
  position: relative;

  &.is-disabled {
    .choices__inner,
    .choices__input {
      @extend %button--disabled;
    }

    .choices__item {
      cursor: not-allowed;
    }
  }

  &[data-type*='select-multiple'] {
    .choices__inner {
      cursor: text;
    }

    .choices__button {
      @extend %button-x;
      position: relative;
      display: inline-block;
    }
  }

  // container for search input and selected items
  &__inner {
    background-color: $input-bg-desktop;
    background-position: right $input-padding-horizontal-desktop top 50%, 0 0;
    margin: 0;
    display: inline-block;
    vertical-align: top;
    width: 100%;
    min-height: 3rem;
    overflow: hidden;
    padding: 0; // override

    .choices__list {
      @extend %select;
      background-color: color('choices-selected-area');
      background-position: right $input-padding-horizontal-desktop top 50%, 0 0;
      box-shadow: none;
      margin: 0;
      min-height: 4em;
      padding: $input-padding-vertical-desktop $input-padding-horizontal-desktop; // override
    }
  }

  &__list {
    list-style: none;

    &--multiple {
      display: block; // default inline, block pushes input search down

      .choices__item {
        @extend %button-selecteditem-withremove;
        display: inline-block;
        vertical-align: middle;
        margin: 0 .4em .4em 0;

        &[data-deletable] {
          padding-right: .3em;
        }

        &.is-highlighted {
          background-color: color('choices-selected-highlighted');
        }
      }
    }

    &--dropdown {
      box-shadow: 0 2em 2em color('shadow');
      display: none;
      z-index: 1;
      position: absolute;
      width: 100%;
      background-color: #fff;
      top: 100%;
      //margin-top: -1px;
      overflow: hidden;
      word-break: break-all;

      &.is-active {
        display: block;
      }

      .choices__list {
        position: relative;
        max-height: 400px;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        will-change: scroll-position;
      }

      .choices__item {
        background-color: transparent;
        border-bottom: 1px solid color('border');
        position: relative;
        padding: $input-padding-vertical-desktop $input-padding-horizontal-desktop;

        &--selectable.is-highlighted {
          background-color: color('choices-selectable-highlighted');

          &::after {
            opacity: .5;
          }
        }
      }
    }
  }

  &__item {
    background-color: color('choices-selected');
    cursor: default;

    &--selectable {
      cursor: pointer;
    }

    &--disabled {
      cursor: not-allowed;
      user-select: none;
      opacity: .5;
    }
  }

  &__button {
    text-indent: -9999px;
    -webkit-appearance: none;
    appearance: none;
    border: 0;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
  }

  &__input {
    background: transparent $magnifier no-repeat right $input-padding-horizontal-desktop top 50%;
    border-color: color('white');
    border-width: 0 0 1px;
    box-shadow: none; // override
    display: block; // changed to display below the chosen items
    vertical-align: baseline;
    margin: 0; // override
    max-width: 100%;
    padding: $input-padding-vertical-desktop $input-padding-horizontal-desktop;
    width: 100% !important; // override inline from js

    &::placeholder {
      font-style: italic;
    }
  }

  &__placeholder {
    color: $placeholder;
    font-style: italic;
  }
}

.is-focused .choices__inner,
.is-open .choices__inner {
  border-color: #b7b7b7;
}

.is-open .choices__inner {
  border-radius: 2.5px 2.5px 0 0;
}

.is-flipped.is-open .choices__inner {
  border-radius: 0 0 2.5px 2.5px;
}

.is-disabled .choices__list--multiple .choices__item {
  background-color: #aaa;
  border: 1px solid #919191;
}

.is-open .choices__list--dropdown {
  border-color: #b7b7b7;
}

.is-flipped .choices__list--dropdown {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: -1px;
  border-radius: .25rem .25rem 0 0;
}

.choices__input.is-hidden,
.choices[data-type*='select-one'] .choices__input.is-hidden,
.choices[data-type*='select-multiple'] .choices__input.is-hidden {
  display: none;
}

@include respond-to('l') {
  .choices__list--dropdown .choices__item--selectable {
    padding-right: 100px;
  }

  .choices__list--dropdown .choices__item--selectable::after {
    content: attr(data-select-text);
    font-size: 12px;
    opacity: 0;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
}

