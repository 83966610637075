// Dependencies.
@import 'navigation/navbar/navbar';
@import 'layouts/layout-center/layout-center';

// Tabs
//
// Pure CSS accessible tabs for displaying bits of content in tabbed interface
// The actual tabs HTML can be separate from the tab content HTML
// The tabs are anchors that target special invisible elements with IDs that 
// are positioned directly above the related tab content container 
// This type of tabs can't work within a :target element because they rely on
// :target to switch tab content
//
// Instead we're using a simpler HTML that is controled by javascript to change
// states and show different tab content, through the use of ARIA attributes
//
//
// Markup: tabs.twig
//
// Weight: 0
//
// Style guide: components.tabs




// Tabs with javascript
//
// Instead we're using a simpler HTML that is controled by javascript to change
// states and show different tab content, through the use of ARIA attributes
//
//
// Markup: tabs.twig
//
// Weight: 0
//
// Style guide: components.tabs-javascript

// This <ul> has [role='tablist']
%tabs-aria-js,
.filter-opcije-nav {
  background-color: color('filter-tabs-bg');
  align-items: stretch;
  display: flex;
  margin: 0;
  padding: 0 2vw;
  width: 100%;
  @include respond-to('l') {
    border-bottom: 1px solid color('border');
    padding: 0;
  }

  [role='tab'] {
    cursor: pointer;
    font: bold #{(10rem / 16)}/1.3 $roboto-condensed;
    letter-spacing: 1px;
    list-style: none;
    padding: 1rem .5rem;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    color: color('filter-tabs');
    // flex-grow can't be 0 because it shrinks to content and doesn't fill space
    // flex-basis must be 0 so they're equal in width, so pseudo-icons are squares
    flex: 1 0 0;
    width: 25%;

    // the square background
    &::before {
      background-color: color('white');
      background-image: $fuel-blue;
      background-position: center;
      background-repeat: no-repeat;
      background-size: calc(1vw + 2rem);
      border-radius: 3px;
      color: color('white');
      content: '';
      display: block;
      margin-bottom: .7rem;
      // method for keeping aspect ratio, square icons, padding same as width
      padding-top: 100%;
      width: 100%;
      height: 0;
    }

    // icon above the square background
    &::after {
      // method for case when the content is higher than the aspect ratio box
      display: table;
      clear: both;
      content: '';
    }

    &:focus,
    &:hover {
      color: color('filter-tabs-hover');
    }

    &[aria-selected='true'] {
      color: color('filter-tabs-current');

      &::before {
        box-shadow: 0 0 0 1vw color('filter-tabs-current') inset;
      }
    }
  }

  // icons for tabs 
  [aria-controls='filter-vg']::before {
    background-image: $teardrop;
  }

  [aria-controls='filter-op']::before {
    background-image: $gear;
  }

  [aria-controls='filter-rv']::before {
    background-image: $clock;
  }

  [aria-controls='filter-ob']::before {
    background-image: $buildings;
  }
}

$filter-tabs-vertical: 2rem*1.2 + 2rem + 2*1.6rem;
// look at $trazi-padding in layout center
$form-submit-font: (14rem / 16);
$submit-form-vertical: (2*$margin-vertical-default + $form-submit-font*$base-line-height);
$map-height: 480px;

%tabs-content-aria-js,
.tabs-content {
  position: relative; // context for tabpanel

  [role='tabpanel'] {
    background-color: color('white'); // just in case by mistake visible overlap
    left: 0;
    padding-bottom: $submit-form-vertical;
    position: absolute;
    top: 0;
    transition: opacity 1s ease 0s;
    width: 100%;
    @include respond-to('l') {
      padding-bottom: 0;
    }

    &[aria-expanded='false'] {
      opacity: 0;
      visibility: hidden;
    }

    &[aria-expanded='true'] {
      opacity: 1;
      visibility: visible;
    }
  }

  h3,
  legend {
    background-color: color('filter-heading-bg');
    font-weight: bold;
    margin: 0;
    padding: .6em 3vw;
    width: 100%;
  }

  label {
    &::before {
      content: '';
      margin: 0;
      position: static; // override trazi-opcije
      transform: none; // override trazi-opcije
    }
  }
}


$padding-horizontal: 3vw; // same as h3
$padding-vertical: $margin-vertical-default;
$padding-formitems: $padding-vertical $padding-horizontal;

.trazilica-filtri {
  label {
    border-bottom: 1px solid color('border');
    color: color('filter-tabs-content');
    padding: $padding-formitems;
    position: static;
    white-space: normal; // override from form items before filters
  }

  input[type='checkbox'] {
    @extend %visually-hidden;
    // make slightly higher than label vertically to to reveal the whole label 
    // otherwise input is too short and can't see bottom border state
    height: 3.2*$padding-vertical;

    &:checked + label {
      background-color: color('filter-tabs-checked-bg');
      background-image: $check;
      background-position: calc(100% - #{$padding-horizontal}) 50%;
      background-repeat: no-repeat;
      color: color('filter-tabs-checked');
    }

    &:focus,
    &:hover {
      & + label {
        border-bottom: 1px solid color('filter-tabs-focus');
      }
    }

    // IE doesn't recognize :disabled on elements other than input, safe option
    &[disabled] + label,
    &:disabled + label {
      color: color('filter-tabs-disabled');
    }
  }

  // labels icons for different input checkboxes
  @each $option, $icon in $postaja-opcije {
    [for='op-#{$option}']::before {
      content: $icon;
    }
  }

  // (header height) - (own height)
  $submit-form-top: 2*$margin-vertical-default + $nav-button-mobile-lineheight*$base-font-size - $submit-form-vertical;

  .form-submit {
    bottom: 0;
    left: 0;
    position: fixed;
    top: auto;
    width: 100%;
    z-index: 10;
    display: flex; // to show buttons in one line
    @include respond-to('l') {
      bottom: auto;
      left: 2vw; // same as form .trazilica
      max-width: 40em;
      top: calc(#{$submit-form-top} + #{$map-height} - 1vw);
      width: calc(100% - 2*2vw); // same as form .trazilica
    }
    @include respond-to('xxxxl') {
      // $gutter-main (2) + (viewportWidth - max-width-for-content)/2
      left: calc(2vw + (100vw - 102.857rem)/2);
    }

    input {
      background-color: color('filter-reset');
      color: color('white');
      font-family: $roboto-condensed;
      font-size: $form-submit-font;
      text-transform: uppercase;

      &[type='submit'] {
        background-color: color('filter-primijeni');
      }
    }
  }
}

// grid-like layout for desktop for Opcije postaje
#filter-op {
  label {
    &::before {
      margin-right: 1em;
    }
  }

  @include respond-to('l') {
    align-items: stretch;
    align-content: stretch;
    display: flex;
    flex-wrap: wrap;
    text-align: center;

    // position the inputs the same as labels to there's not weird jump onclick
    input[checkbox],
    label {
      border-right: 1px solid color('border');
      line-height: 1.3;
      padding-left: 1vw;
      padding-right: 1vw;
      text-align: center;
      width: 25%;
      display: flex;
      flex-direction: column;
      justify-content: center; // center column flex vertically
    }

    label {
      background-position: 10% 10%;

      &::before {
        display: block;
        margin: 0 0 1em;
      }
    }

    h3 {
      display: none;
    }
  }
}

