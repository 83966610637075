// sass-lint:disable no-important

// bgpattern-squares()
//
// Background pattern created from fixed px values given in an Adobe mockup 
// using linear gradients, background-size, and background-position while 
// keeping aspect ratio constant with the modified "fluid aspect ratio"  
// 
// $color-full = color('kvadrati-uzorak') - Color for the squares fully opaque
// $bg-blend   = color('white') - Color of the background that squares blend into
// $percent    = 5% - Percentage of opacity to apply to the full color to blend
//
// Markup: bgpattern-squares.twig
//
// Style guide: sass.mixins.bgpattern-squares

// TODO refactor

// Fluid aspect ratio modified method
//
// The Core Concept: padding in percentages is based on width
// https://css-tricks.com/aspect-ratio-boxes/
// We can't use the floated invisible pseudo-element because our bg is on it 
// ::before pseudo-element is made to keep the set aspect ratio with padding
// and 0 height so our background properties can be calculated to % from fixed 

$canvas-width:  1280;
$canvas-height:  612;
$ratio: $canvas-width/$canvas-height;
$square1-side:   207;
$square1-x:     1073;
$square2-side:   125;
$square2-x:      365;
$square3-side:    47;
$square3-y:      214;
$square4-side:    47;
$square4-x:      782;
$square4-y:      184;
$square5-side:    92;
$square5-x:      107;
$square5-y:      520;
$square6-side:   125;
$square6-x:      862;
$square6-y:      418;

@mixin bgpattern-squares($color-full: color('kvadrati-uzorak'), $bg-blend: color('white'), $percent: 5%) {
  background-color: $bg-blend;
  overflow: hidden; // hide ::before squares if content short
  // vertical and horizontal padding must be equal to keep background ratio!
  padding: $gutter-main;
  margin: $margin-vertical-default auto;
  // context for background pseudo-element
  position: relative;
  z-index: 0;

  //$kvadrati-uzorak-transparent: rgba(color('kvadrati-uzorak'), .05);
  //$percent: alpha($kvadrati-uzorak-transparent) * 100%;
  $bgpattern-solid: mix($color-full, $bg-blend, $percent);

  &::before {
    background-color: $bg-blend;
    background-image:
      linear-gradient($bgpattern-solid 0, $bgpattern-solid 100%),
      linear-gradient($bgpattern-solid 0, $bgpattern-solid 100%),
      linear-gradient($bgpattern-solid 0, $bgpattern-solid 100%),
      linear-gradient($bgpattern-solid 0, $bgpattern-solid 100%),
      linear-gradient($bgpattern-solid 0, $bgpattern-solid 100%),
      linear-gradient($bgpattern-solid 0, $bgpattern-solid 100%);
    background-position:
      ($square1-x + $square1-side)*100%/$canvas-width 0,
      ($square2-x + $square2-side)*100%/$canvas-width 0,
      0 ($square3-y + $square3-side)*100%/$canvas-height,
      ($square4-x + $square4-side)*100%/$canvas-width ($square4-y + $square4-side)*100%/$canvas-height,
      ($square5-x + $square5-side)*100%/$canvas-width ($square5-y + $square5-side)*100%/$canvas-height,
      ($square6-x + $square6-side)*100%/$canvas-width ($square6-y + $square6-side)*100%/$canvas-height;
    background-repeat: no-repeat;
    background-size:
      $square1-side*100%/$canvas-width $square1-side*100%/$canvas-height,
      $square2-side*100%/$canvas-width $square2-side*100%/$canvas-height,
      $square3-side*100%/$canvas-width $square3-side*100%/$canvas-height,
      $square4-side*100%/$canvas-width $square4-side*100%/$canvas-height,
      $square5-side*100%/$canvas-width $square5-side*100%/$canvas-height,
      $square6-side*100%/$canvas-width $square6-side*100%/$canvas-height;
    content: '';
    display: block;
    height: 0;
    left: 0;
    padding-top: ($canvas-height / $canvas-width) * 100%;
    position: absolute;
    top: 0;
    width: 100%;
    // push behind parent content
    z-index: -1;
  }
}
