// Headings
//
// All HTML headings, `<h1>` through `<h6>`, are available. `%h1` through `%h6`
// classes are also available, for when you want to match the font styling of a
// heading but still want your text to be displayed inline.
//
// Markup: headings-h.twig
//
// Style guide: base.headings

// Address variable `h1` font-size and margin within `section` and `article`
// contexts in Firefox 4+, Safari, and Chrome.

$heading-line-height-default: 1.2;
$heading-lower-line-height: 1.3;

@mixin heading-default($f-size, $l-height: $heading-line-height-default) {
  color: color('heading');
  font: normal bold #{map-get($font-size, $f-size)}/#{$l-height} $font-family-accent;
  margin: map-get($font-size, $f-size) 0 (map-get($font-size, $f-size)/2);
}

h1,
%h1 {
  @include heading-default('xxl');
}

h2,
%h2 {
  @include heading-default('xl');

  &:first-child {
    margin-top: 0;
  }
}

h3,
%h3 {
  @include heading-default('l');
}

h4,
%h4 {
  @include heading-default('h4', $heading-lower-line-height);
}

h5,
%h5 {
  @include heading-default('h5', $heading-lower-line-height);
}

h6,
%h6 {
  @include heading-default('m', $heading-lower-line-height);
  font-weight: bold;
}
