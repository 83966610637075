// sass-lint:disable no-important

// color-mark()
//
// Background pattern created from fixed px values given in an Adobe mockup 
// using linear gradients, background-size, and background-position while 
// keeping aspect ratio constant with the modified "fluid aspect ratio"  
// 
// $color = color('mark') - Main color used for marking background and text
//
// Markup: color-mark.twig
//
// Style guide: sass.mixins.color-mark

@mixin color-mark($color: color('mark')) {
  background-color: scale-color($color, $lightness: 80%);
  border-radius: .2em;
  color: $color;
  padding: .2em .4em .1em; // because uppercase
  text-transform: uppercase;
}
