// Dependencies.
@import 'components/visually-hidden/visually-hidden';

// Navbar
//
// A simple method to get navigation links to appear in one line.
//
// Markup: navbar.twig
//
// Style guide: navigation.navbar

// Pure CSS responsive navigation menu 
// https://medium.com/@heyoka/responsive-pure-css-off-canvas-hamburger-menu-aebc8d11d793

$navbar-mobile-maxwidth: 18em; // under 14px 25em font that's 320px

$nav-button-mobile-fontsize: (36rem / 16);
$nav-button-mobile-lineheight: 1.5;
// isti line-height za desktop
$nav-button-desktop-lineheight: $nav-button-mobile-lineheight;
$nav-button-mobile-padding-v: 0;
$nav-button-mobile-padding-h: 1rem;

// on mobile side these buttons control the height of the "header" area
// Open nav button on site content, and Close nav button on the navigation
$nav-button-mobile-vertical: $nav-button-mobile-fontsize*$nav-button-mobile-lineheight + 2*$nav-button-mobile-padding-v;

%navbar-button {
  background-color: color('nav-hamburger-bg');
  color: color('white');
  padding: $nav-button-mobile-padding-v $nav-button-mobile-padding-h;
  line-height: $nav-button-mobile-lineheight;
  font-size: $nav-button-mobile-fontsize;

  &:focus,
  &:hover {
    text-shadow: 1px 1px 0 color('black');
    text-decoration: none;
  }
}

// Link to open main navbar, visible on mobile
.navbar-open {
  @extend %navbar-button;
}

// main nav container 
.navbar,
%navbar {
  background-color: color('nav-hamburger-bg');
  font-family: $roboto;
  font-size: (20rem / 16);
  height: 100vh;
  line-height: $nav-button-mobile-lineheight;
  margin: 0;
  max-width: $navbar-mobile-maxwidth;
  overflow-x: visible;
  overflow-y: scroll;
  padding: 0;
  position: absolute;
  text-align: left;
  transform: translateX(-$navbar-mobile-maxwidth);
  transition: transform .6s ease 0s;
  top: 0;
  width: 100%;
  z-index: 30;
  // position first two children left and right
  align-items: center;
  align-content: flex-start;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @supports (position: fixed) {
    position: fixed;
  }

  // Heading for the nav on mobiles
  & > h2 {
    background-color: color('nav-hamburger-bg');
    color: color('nav-hamburger');
    font-size: (20rem / 16);
    font-weight: normal;
    margin: 0;
    padding-left: 1em;
  }

  // Link to close main navbar, visible on mobile
  &-close {
    @extend %navbar-button;
    z-index: 32;
  }

  // List in main nav 
  &-ul {
    background-color: color('navbar-mobile-bg');
    list-style: none;
    margin: 0;
    min-height: calc(100vh - #{$nav-button-mobile-vertical});
    padding: $margin-vertical-default 0 0;
    position: relative;
    width: 100%;
    z-index: 31;
  }

  // Individual list items of main nav
  &-item,
  li {
    padding: 0;
    list-style-type: none;
    list-style-image: none;
  }

  // Anchors inside man nav list items, they carry most of the styling
  &-link,
  &-prijava,
  &-registracija {
    color: color('text');
    display: block;
    margin-bottom: .4em;
    padding: .8em 1em .8em 3em;
    // context for pseudo underline
    position: relative;
    text-decoration: none;

    &:focus,
    &:hover {
      background-color: color('link');
      color: #fff;
      text-decoration: none;
    }
  }

  // Anchor that links to the page you're currently viewing
  &-current {
    color: color('nav-current');

    &:focus,
    &:hover {
      color: color('white');
    }
  }

  // The last two anchors in main nav with special styles
  &-prijava,
  &-registracija {
    background-color: color('nav-registracija');
    color: #fff;
    font-weight: bold;
    margin: .4em 4vw;
    min-width: 8em;
    padding: $margin-vertical-default;
    text-align: center;

    &.navbar-current {
      background-image: none;
    }
  }

  &-prijava {
    background-color: color('nav-prijava'); // override
    margin-top: 3em;

    &::before {
      background-color: color('border');
      content: '';
      display: block;
      height: 1px;
      left: 0;
      position: absolute;
      top: -1em;
      width: 100%;
    }
  }

  // Styles for the main nav when it's opened on mobiles
  &:target {
    animation-name: none;
    // for positioning children
    outline: none;
    text-align: left;
    transform: translateX(0);
    transition: transform .6s ease 0s;
  }
}

// only visible to screen readers because they don't see the hamburger
.label-for-main-nav,
.label-for-main-nav-toggle {
  @extend %visually-hidden;
}

// Desktop styles, we must override some mobile styles 
@include respond-to('l') {
  .navbar {
    display: block;
    font-family: $roboto-condensed;
    font-size: (14rem / 16);
    height: auto;
    left: auto;
    max-width: none; // override
    overflow-y: auto;
    position: relative;
    text-align: center;
    top: auto;
    transform: none;
    transition: none;
    width: auto; // override

    // can't use @extend within @media, must use mixin
    & > h2 {
      @include visually-hidden();
    }

    &-open,
    &-close {
      display: none;
    }

    &-ul {
      display: flex;
      height: auto;
      min-height: 0;
      padding: 0;
      width: auto;
    }

    &-link {
      margin: 0;
      padding: $margin-vertical-default 1em;
    }

    &-prijava,
    &-registracija {
      margin: 0;
    }

    &-prijava {
      margin-left: 4vw;

      &::before {
        display: none;
      }
    }

    // Anchor that links to the page you're currently viewing
    // Special pseudo underline made with linear gradient to make it thicker
    &-current {
      color: inherit;
      $vertical-distance: 2.8rem;
      $border-height-rem: 2rem/14;
      $border-vertical-distance: $vertical-distance + $border-height-rem;
      background-image: linear-gradient(
        transparent $vertical-distance,
        black $vertical-distance,
        black $border-vertical-distance,
        transparent $border-vertical-distance);
      background-position: center;
      background-repeat: no-repeat;
      background-size: calc(100% - 2em) 100%;
    }
  }
}
