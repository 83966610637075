// Dependencies.
@import 'navigation/navbar/navbar';
@import 'base/forms/forms';
@import 'base/text/text';
@import 'components/warning/warning';
@import 'components/choices/choices-custom';
@import 'components/postaja/postaja';

// Layout Center
//
// Full viewport width to accomodate for the map and the sections of the page 
// with different backgrounds that go screen edge to edge, but with the content 
// within sections constrained in width. 
//
//
// Markup: layout-center.twig
//
// Weight: -1
//
// Style guide: layouts.layout-center

// If your page design background doesn't stretch wider than its content 
// apply this to the body element.
// If your page sections have backgrounds that stretch edge to edge of viewport
// but the content needs to have width limits (which it should) 
// apply this to the inner wrapper of each section (wrapper that holds content)

$content-maxwidth: 102.857rem;

%layout-content-width {
  // center content horizontally
  margin-left: auto;
  margin-right: auto;
  // limit content width, good for readability
  max-width: $content-maxwidth;
  // don't let content stick to the left and right viewport edge
  padding-left: $gutter-main;
  padding-right: $gutter-main;
}


header[role='banner'],
.trazilica-obrazac,
.trazilica-rezultati,
.cijene-emailom-obrazac,
footer[role='contentinfo'] {
  @extend %layout-content-width;
}

header[role='banner'] {
  // center it by first moving to right half of screen, then pull back
  // with transform
  left: 50%;
  // don't restrict the mobile menu
  overflow: visible;
  padding: 0; // override
  position: fixed;
  transform: translateX(-50%);
  width: 100%;
  // careful to go over the form
  z-index: 10;
  // position children horizontally left and right
  align-items: center;
  display: flex;
  justify-content: space-between;
  @include respond-to('l') {
    z-index: 11;
  }
}

#main-content {
  padding-top: $nav-button-mobile-vertical;
  // context for map
  position: relative;
  @include respond-to('l') {
    // calculated from navbar link vertical measures
    padding-top: 2*$margin-vertical-default + $nav-button-mobile-lineheight*$base-font-size;
  }
}

// Karta i trazilica 

$map-height: 480px;
$form-submit-font: (14rem / 16);
$submit-form-vertical-calc: (2*$margin-vertical-default + $form-submit-font*$base-line-height);
// adds 1vw gutter on top and bottom
$trazilica-height: calc(#{$map-height} - 2vw - #{$submit-form-vertical-calc});
$trazilica-otvorene-height: calc(#{$map-height} - 4vw - #{2*$submit-form-vertical-calc});

.trazilica-goriva {
  position: relative;
}

.trazilica-karta {
  background-color: color('karta-bg');
  left: 0;
  min-height: $map-height;
  position: relative;
  top: 0;
  width: 100%;
  z-index: 0;
}

.trazilica-obrazac {
  padding-top: 0;
  position: relative; // positioning context for child
  @include respond-to('l') {
    z-index: 0;
  }

  & > h1 {
    @extend %visually-hidden;
  }
}

$trazi-padding: $margin-vertical-default;

// form element
.trazilica {
  left: $gutter-main;
  height: $trazilica-height; // reach bottom of map
  position: absolute; // context for z-index
  top: calc(1vw - #{$map-height}); // reverse of $trazilica-height
  width: calc(100% - 2*#{$gutter-main});
  // must not include z-index to allow header navigation to go over form trazi
  @include respond-to('l') {
    max-width: 40em;
    z-index: 0; // so descendant .form-submit goes behind following content
  }

  [for='lokacija'],
  .label-for-otvorene {
    @extend %visually-hidden;
  }

  input,
  select,
  .otvori-filtre {
    background-color: transparent;
    box-shadow: none;
    color: inherit;
    margin: 0;
    padding: $trazi-padding;
    position: relative; // give context for z-index
    width: 100%;
    //z-index: 1;

    &:focus,
    &:active {
      background-color: color('white');
    }
  }

  .otvori-filtre {
    @extend %text-form-element;
    background-color: color('white');
    color: color('otvori-filtre');
    display: block;
    text-align: center;
    @include respond-to('l') {
      color: color('otvori-filtre-desktop');
      text-align: left;
    }
  }

  select {
    // remove the dropdown arrow, but keep linear gradient for browsers
    background-image: linear-gradient(to bottom, $input-bg 0%, $input-bg 100%);
    background-repeat: repeat;
    background-position: 0 0;
    background-size: 100%;
  }

  // If there is nothing selected it's invalid, option is empty, so show the
  // label behind the select element
  select:invalid {
    background-color: transparent;
  }

  // If there is something selected make the select opaque so label text and
  // icon aren't visible meshing with the selected option 
  select:valid {
    background-color: color('white');
  }
}

.trazi-opcije {
  color: color('trazi-opcije');
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  @include respond-to('l') {
    color: color('trazi-opcije-desktop');
  }

  // position it behind its select element
  label {
    color: transparent;
    display: block;
    left: 0;
    // match input
    padding: $trazi-padding;
    position: absolute;
    top: 0;
    white-space: nowrap; // keep icon position on mobile when words wrap
    width: 100%;
    z-index: 0;
    @include respond-to('l') {
      color: inherit;
      //padding: $trazi-padding;
    }

    &::before {
      color: color('select-icon');
      content: $fuel-blue;
      display: inline-block;
      height: 1em;
      left: 50%;
      margin-right: .8em;
      position: absolute;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      vertical-align: sub;
      width: auto;
      @include respond-to('l') {
        color: color('select-icon-desktop');
        content: $fuel-grey;
        position: static;
        transform: none;
      }
    }
  }

  .traziradius-label {
    &::before {
      content: $distance-blue;
      @include respond-to('l') {
        content: $distance-grey;
      }
    }
  }
}



$lokacija-placeholder-fontstyle: italic;

#lokacija {
  background-color: color('white');
  border: 0;
  box-shadow: none;
  display: block;
  font-size: (20rem / 16);
  margin: 0 0 2px;
  padding: $margin-vertical-default;
  width: 100%;

  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    font-style: $lokacija-placeholder-fontstyle;
  }

  &:-moz-placeholder { // /* Firefox 18- */
    font-style: $lokacija-placeholder-fontstyle;
  }

  &::-moz-placeholder { // /* Firefox 19+ */
    font-style: $lokacija-placeholder-fontstyle;
  }

  &:-ms-input-placeholder { // /* IE 10+ */
    font-style: $lokacija-placeholder-fontstyle;
  }

  &::-ms-input-placeholder { // /* Microsoft Edge */
    font-style: $lokacija-placeholder-fontstyle;
  }

  &::placeholder { // /* Chrome, Firefox, Opera, Safari 10.1+ */
    font-style: $lokacija-placeholder-fontstyle;
  }
}



$button-otvorene-postaje-fontsize: (36rem / 16);
$button-otvorene-postaje-lineheight: 1.5;
$button-otvorene-postaje-padding-v: 0;
$button-otvorene-postaje-padding-h: 1rem;

%button-otvorene-postaje {
  background-color: color('white');
  color: color('nav-hamburger-bg');
  padding: $button-otvorene-postaje-padding-v $button-otvorene-postaje-padding-h;
  line-height: $button-otvorene-postaje-lineheight;
  font-size: $button-otvorene-postaje-fontsize;

  &:focus,
  &:hover,
  &:active {
    text-shadow: 1px 1px 0 color('black');
    text-decoration: none;
  }
}

.otvori-otvorene-postaje,
.trazi-lokacija > button[type='submit'] {
  @extend %button-otvorene-postaje;
  background-color: color('white');
  display: block;
  line-height: 1.6;
  margin: 0;
  padding: .8rem $margin-vertical-default .8rem .8rem;
  position: absolute;
  right: 0;
  top: 0;
  width: auto; // override
  z-index: 3;
}

.otvori-otvorene-postaje {
  display: none;
  font-size: 4em;
  line-height: 1;
  padding: .8rem;
  right: 4.7rem;
  @include respond-to('l') {
    display: block;
  }
}

// div container for select, to show icon in label behind select
.trazivrstagoriva,
.traziradius {
  background-color: color('white');
  border-right: 1px solid color('border');
  position: relative; // context for span label
}

.trazivrstagoriva,
.traziradius,
.trazi-opcije .otvori-filtre {
  align-self: stretch;
  width: (100% / 3);
}

.icon-svg {
  line-height: 1.5;
  margin-right: .8em;
  vertical-align: sub;
}

// TODO refactor the mobile case of showing across full screen on :target
// together with navbar, make sense to build a custom component for it with
// overrides for desktop at the same time, mixin with width arg? 

$filter-natrag-padding-vertical: 1rem;
$filter-natrag-vertical: $base-line-height*$base-font-size + 2*$filter-natrag-padding-vertical;

.trazilica-filtri {
  @extend %target-clear-fixed-header;
  background-color: color('white');
  //display: none;
  display: block; // for :target clear fixed header
  height: 100vh;
  left: 0;
  margin: 0;
  overflow-x: visible;
  overflow-y: scroll;
  padding: 0;
  position: absolute;
  transform: translateX(-100vw);
  transition: transform .6s ease 0s;
  top: 0;
  width: 100vw;
  z-index: 30;
  @supports (position: fixed) {
    position: fixed;
  }
  @include respond-to('l') {
    height: auto;
    left: 0;
    min-height: $trazilica-height;
    overflow-y: auto;
    position: absolute;
    top: 0;
    width: 100%; // override
  }

  h2 {
    color: color('filter-heading');
    font-size: (22rem / 16);
    font-weight: normal;
    left: calc(2vw + 1em); // add to .zatvori-filter padding left
    line-height: 1;
    margin: 0;
    padding: $filter-natrag-padding-vertical 0;
    position: absolute;
    z-index: 0;
    @include respond-to('l') {
      color: color('filter-heading-desktop');
    }
  }
}

// We're using target to a span above the actual element we want to target
// to prevent jumping of the page to the top of the element if the user has
// scrolled further below
#trazilica-filtri-jump {
  display: none;
  position: fixed;

  &:target {
    // positionfor filters options when opened
    & + .trazilica-filtri {
      animation-name: none;
      display: block;
      // for positioning children
      outline: none;
      text-align: left;
      // must comletely remove transform so the positioned sticks to bottom
      // transform elements create their own coordinate system
      // https://stackoverflow.com/questions/15152712/css-positionfixed-inside-of-positionabsolute
      // transform: translateX(0);
      transform: none;
      transition: transform .6s ease 0s;
    }
  }
}

#trazilica-otvorene-jump {
  display: none;
  position: fixed;

  &:target {
    // positionfor filters options when opened
    & + .trazilica-rezultati-otvorene {
      animation-name: none;
      // for positioning children
      outline: none;
      transform: translateX(0);
      transition: transform .6s ease 0s;
    }
  }
}

// rezultati ispod glavne forme preko karte
// position slican kao kod form .trazilica
.trazilica-rezultati-otvorene {
  background-color: color('white');
  // hide from mobiles
  display: none;
  left: $gutter-main;
  height: $trazilica-otvorene-height; // reach bottom of map
  overflow: auto;
  position: absolute; // context for z-index
  top: calc(3vw - #{$map-height} + #{2*$submit-form-vertical-calc}); // look .trazilica, from bottom of map
  transform: translateX(-100vw);
  transition: transform .6s ease 0s;
  width: calc(100% - 2*#{$gutter-main});
  z-index: 0; // so descendant .form-submit goes behind following content
  @include respond-to('l') {
    display: block;
    max-width: 40em;
  }

  h2 {
    background-color: color('otvorene-heading-bg');
    color: color('otvorene-heading');
    font-family: $roboto-condensed;
    font-size: (14rem / 16);
    letter-spacing: 1px;
    margin: 0;
    padding: .8em $trazi-padding .6em;
    text-transform: uppercase;
  }

  .postaja {
    @include postaja-compact-vertical();
  }
}

.zatvori-filtre {
  display: block;
  padding: $filter-natrag-padding-vertical 2vw;
  position: relative; // context for h2 z-index
}

.label-for-filter-toggle {
  @extend %visually-hidden;
}




.trazilica-rezultati,
.cijene-emailom-obrazac,
footer[role='contentinfo'] {
  background-color: color('white');// to cover .form-submit
  padding: 4*$margin-vertical-default $gutter-main 2*$margin-vertical-default;
  position: relative; // context to go over .form-submit
  text-align: center;
  z-index: 1;
}

// This creates invisible element and positions it after all content
// within this element that has the (default) order 0.
// The child that gets order 2 is then positioned after the break and is out 
// of the flex flow starting a new row
// TODO refactor into separate helper component, with the element after break
// create a child that breaks the flex flow
%flexbox-break-context {
  display: flex;

  &::after {
    content: '';
    display: block;
    height: 0;
    width: 100%;
    // default is 0 so 1 is in the end, but then 2 orders after the break
    order: 1;
  }
}

.trazilica-rezultati {
  @extend %flexbox-break-context;
  background-color: color('trazilica-rezultati');
  flex-flow: row wrap;
  justify-content: center;

  // TODO refactor into separate h2 with icon, also Primajte cijene mailom
  h2 {
    margin-bottom: 3*$margin-vertical-default;
    flex: 0 0 100%;

    &::before {
      content: $fuel-blue-large;
      display: block;
      margin: 0 0 .5em;
    }
  }
}

.postaje-sve,
.load-more,
.obveznici-jos {
  @extend %button-hollow;
  flex: 0 1 100%;
  align-self: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 12em;
  position: relative; // context for ::after
  text-align: left;
  // the parent must have the ::after break ordered 1, so this comes after it
  order: 2;

  &::after {
    content: $right-arrow-blue;
    right: 2em;
    margin-left: 1em;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  &:focus,
  &:hover {
    &::after {
      content: $right-arrow-white;
    }
  }
}

.cijene-emailom-obrazac {
  @include bgpattern-squares;
  // move vertical margin to padding
  margin-bottom: 0;
  margin-top: 0;
  padding-top: 3*$margin-vertical-default;
  text-align: center;
  z-index: 1; // for .submit-form

  &::before {
    top: 3*$margin-vertical-default;
  }

  h2 {
    margin-bottom: 3*$margin-vertical-default;

    &::before {
      content: $mail;
      display: block;
      margin: 0 0 .5em;
    }
  }
}


$gutter-projekt: 8vw;

%layout-content-width-projekt {
  @extend %layout-content-width;
  padding-left: $gutter-projekt;
  padding-right: $gutter-projekt;
}

// Kontakt
.projekt-opis {
  color: color('projekt');
  @include respond-to('l') {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }

  h1 {
    @extend %layout-content-width-projekt;
    flex: 1 0 100%;
  }
}

.projekt-tekst {
  @extend %layout-content-width-projekt;
  padding-bottom: $margin-vertical-default;
  padding-top: $margin-vertical-default;
  @include respond-to('l') {
    margin-left: 0;
    margin-right: 0;
    width: 30em;
  }
}

.projekt-slika {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

@include respond-to('l') {
  .projekt-tekst,
  .projekt-slika {
    flex: 1 0 50%;
    align-self: center;

    &:nth-of-type(2n) {
      order: 2;
    }
  }

  .projekt-slika:nth-of-type(2n) {
    order: 1;
  }
}

.projekt-brojke {
  @extend %layout-content-width-projekt;
  @include bgpattern-squares($color-full: color('min2go-blue'), $bg-blend: color('projekt-brojke'), $percent: 5%);
  margin-bottom: $margin-vertical-default;
  padding-bottom: 2*$margin-vertical-default;
  padding-top: 2*$margin-vertical-default;
  text-align: center;
  flex: 1 0 100%;

  h2,
  dl {
    @extend %layout-content-width-projekt;
  }

  h2 {
    font-size: (14rem / 16);
  }

  $dl-swap: 6rem;

  dt {
    font-size: (20rem / 16);
    font-weight: normal;
    margin-top: ($dl-swap + 2rem); // +2 to ensure larger gap
  }

  dd {
    color: color('projekt-brojke-dd');
    font-size: (40rem / 16);
    font-weight: bold;
    margin: -$dl-swap 0 0;
  }
}

@include respond-to('l') {
  .projekt-brojke {
    // order all dt before all dd, then make them third shared space and wrap
    dl {
      margin: 0;
      display: flex;
      flex-flow: row wrap;
    }

    dt,
    dd {
      margin: 0;
      padding: 0;
      flex: 0 0 33.33%;
    }

    dt {
      order: 2;
    }

    dd {
      order: 1;
    }
  }
}


%karta {
  background-color: color('karta-bg');
  margin-top: -$margin-vertical-default;
  height: $map-height;
}


// Postaja individualna stranica
.postaja {
  &-stranica {
    h1 {
      text-align: center;

      &::before {
        content: $fuel-blue-large;
        display: block;
        margin: 0 0 .5em;
      }
    }

    h1,
    .postaja,
    .postaja-opcije > h2,
    .postaja-opcije > ul,
    .postaja-grafikon {
      @extend %layout-content-width;
    }

    .postaja-rv {
      @include color-mark();
      display: table;
      margin: $margin-vertical-default auto;
      position: static;
      text-align: center;

      dt,
      dd {
        @include color-mark();
        display: table-cell;
        font-size: (11rem / 16);
        font-weight: bold;
        padding-bottom: 0; // override, parent has the padding
        padding-top: 0;
      }
    }

    .postaja-lokacija {
      display: table;
      margin: $margin-vertical-default auto;

      li {
        display: table;
      }
    }

    .postaja-cijene {
      // adjust to padding of the container, now we obey global content width
      margin-left: -2vw;
      margin-right: -2vw;
      @include respond-to('l') {
        margin-left: 0;
        margin-right: 0;
        justify-content: center;

        [data-postaja-gorivo] {
          flex: 0 1 auto;
          margin-left: 0;
          margin-right: 0;
          padding-left: 4vw;
          padding-right: 4vw;
        }
      }
    }
  }

  &-links {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin: $margin-vertical-default auto;
    text-align: center;
  }

  &-odvedi {
    @extend %button;
    margin-left: 1em; // keep centered if 2 flex rows
    margin-right: 1em;  // space between buttons
    min-width: 12em;
  }

  &-share {
    @extend %button-hollow;
    @include respond-to('l') {
      min-width: 12em;
    }
  }

  &-opcije {
    @include bgpattern-squares($bg-blend: color('postaja-opcije-bg'), $percent: 5%);
    color: color('postaja-opcije');
    padding: 4rem 2*$gutter-main;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-content: center;

    ul {
      list-style: none;
      @include respond-to('l') {
        display: flex;
        line-height: 1.5;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: center;
      }
    }

    li {
      margin-bottom: 1em;
      @include respond-to('l') {
        margin-bottom: 2em;
        margin-right: 2em;
        width: 10em;
      }
    }
  }

  &-napomena {
    @extend %warning-background-napomena;
  }

  &-karta {
    @extend %karta;
  }

  &-grafikon {
    padding: 2*$margin-vertical-default $gutter-main;
  }
}


.obveznici {
  &-stranica {
    h1,
    .traziob-label {
      display: none;
    }
  }

  &-karta {
    @extend %karta;
    left: 0;
    margin-top: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
  }

  &-trazilica {
    min-height: $map-height;
    padding: $gutter-main;
    position: relative;
    z-index: 1;
    @include respond-to('l') {
      padding: 4rem 2*$gutter-main;
    }
  }

  // same as Naslovna .trazilica-rezultati TODO refactor into component
  // there is also pseudoelement that breaks flow to make link display in new row
  &-rezultati {
    @extend %flexbox-break-context;
    padding: $margin-vertical-default $gutter-main;
    flex-flow: row wrap;
    justify-content: center;
    @include respond-to('l') {
      display: block;

      .postaja {
        @include postaja-compact-vertical();
      }
    }
  }

  &-jos {
    @extend %button-hollow;
    min-width: 10em;
    order: 2;
    @include respond-to('l') {
      display: block;
    }
  }
}

// for showing two siblings in 2 columns of equal height, full screen width
@mixin col2-halfscreen() {
  display: table-cell;
  width: 50vw;
  @include respond-to('xxxxl') {
    padding-left: calc(#{$gutter-main} + 50vw - #{$content-maxwidth}/2);
  }
}

@include respond-to('l') {
  .postaja {
    &-opcije,
    &-karta {
      @include col2-halfscreen;
    }
  }

  .obveznici {
    &-trazilica,
    &-karta {
      @include col2-halfscreen;
      position: relative;
    }

    &-trazilica {
      background-color: color('obveznici-bg');
    }
  }
}


%icon-in-background {
  background-position: left center;
  background-repeat: no-repeat;
  padding-left: 2em;
}

@each $option, $icon in $postaja-opcije {
  .op-#{$option} {
    @extend %icon-in-background;
    background-image: $icon;
  }
}

