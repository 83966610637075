// sass-lint:disable no-vendor-prefixes

// Use the saner border-box model for all elements.
* {
  box-sizing: border-box;
}

:root {
  font-size: $base-browser-font-size;
}

html {
  font-size: $base-browser-font-size;
  // Prevent adjustments of font size after orientation changes in IE and iOS.
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  // On short pages, we want any background gradients to fill the entire height
  // of the browser.
  min-height: 100%;
}

body {
  font: normal #{$base-font-size}/#{$base-line-height} $font-family-default;
  // Remove default margin and padding.
  margin: 0;
  padding: 0;
  color: color(text);
  background-color: color(text-bg);
}
