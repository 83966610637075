// Skip link
//
// To make the link completely hidden until a user tabs to the link, combine it
// with the `visually-hidden` component.
//
// :focus - Focus styling.
//
// Markup: skip-link.twig
//
// Style guide: navigation.skip-link

.skip-link,
%skip-link {
  @extend %visually-hidden--focusable;
  box-shadow: 0 0 .4em color('black');
  display: block;
  font-weight: bold;
  letter-spacing: 1px;
  margin: 1em;
  padding: 1em;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  top: 0;

  &:link,
  &:visited {
    background-color: color(skip-link-bg);
    color: color(skip-link);
  }

  @media print {
    display: none;
  }
}

.nav-access {
  list-style: none;
  // center horizontally for absolute position, left then transform back
  left: 50%;
  margin: 0;
  padding: 0;
  position: absolute;
  transform: translateX(-50%);
  // stretch full width but must adjust for gutters to prevent horizontal leak
  width: calc(100% - 2*#{$gutter-main});
  // over the header, but below anything else that pops up
  z-index: 11;
}
