// Dependencies.
@import 'components/chartist/chartist';

// Chartist Custom
//
// Changes made to styles using the Chartist mixins
// Here you can override the default options from the settings file that comes
// with the Chartist library
//
// Markup: chartist.twig
//
// Style guide: components.chartist-custom

.chartist-grafikon {
  $legend-vertical: 3rem;
  padding-top: $legend-vertical; // make room for chart legend
  position: relative;
  @include respond-to('l') {
    border: 1px solid color('border');
  }

  .ct-legend {
    margin: 0;
    position: absolute;
    right: 0;
    top: 1rem;
    z-index: 5;

    li {
      color: color('postaja-chartist-legend');
      cursor: pointer;
      display: inline-block;
      margin: 0 0 0 2vw;
      padding: .5rem 2vw;

      &::after {
        border-radius: .2rem;
        content: '';
        display: inline-block;
        height: 1rem;
        margin-left: .4rem;
        vertical-align: text-bottom;
        width: 1rem;
      }

      &.inactive {
        &::after {
          background-color: transparent;
        }
      }
    }
  }

  & > svg {
    top: $legend-vertical;
  }
}

// @for $i from 0 to length($ct-series-colors) {
// we only have 3 series of data
@for $i from 0 through 2 {
  // generate colors for chartist legend items
  .ct-series-#{$i} {
    &::after {
      background-color: nth($ct-series-colors, $i + 1);
      border: 2px solid nth($ct-series-colors, $i + 1);
    }
  }

  // change point label colors to match their data set
  .ct-series-#{nth($ct-series-names, $i + 1)} {
    .ct-label {
      @include ct-chart-label($ct-text-color: nth($ct-series-colors, $i + 1));
      display: none;
      @include respond-to('l') {
        display: flex;
      }
    }
  }
}

// Change axis font size on xxxs
.ct-label {
  @include ct-chart-label($ct-text-size: $ct-text-size-mobile);
  @include respond-to('xxxs') {
    @include ct-chart-label($ct-text-size: $ct-text-size);
  }
}

