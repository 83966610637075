// Dependencies.
@import 'components/visually-hidden/visually-hidden';

// Pages
//
// Page elements styles not directly related to layout 
//
// Markup: pages.twig
//
// Style guide: pages

// Header 
header[role='banner'] {
  // not transparent when other content scrolls behind
  background-color: #fff;
  box-shadow: 0 0 4px color('shadow');
}

// Logo 
.logo-min2go {
  display: block;
  // if the image is missing the logo will resemble the image
  font-size: map-get($font-size, 'h4');
  // make anchor focus area equal around image, but align with content 
  margin: 0 $margin-vertical-default/2 0 0;
  padding: $margin-vertical-default/2 $gutter-main;

  img {
    // because inline vertical spacing isn't equal top and bottom 
    display: block;
  }
}
