// Button
//
// In addition to the default styling of `<button>` and
// `<input type="submit|image|reset|button">` elements, the `.button` class and
// its variants can apply buttons styles to various elements (like an `<a>`
// link).
//
// :hover  - Hover styling.
// :active - Depressed button styling.
//
// Markup: button.twig
//
// Style guide: forms.button

// 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
//    controls in Android 4.
// 2. Correct the inability to style clickable types in iOS and Safari.
.button,
%button,
button,
// 1
html [type='button'],
[type='reset'],
[type='submit'] {
  -moz-appearance: button;
  -webkit-appearance: button; // 2
}

.button,
%button,
button,
[type='button'],
[type='reset'],
[type='submit'] {
  @extend %button--disabled;
  // Some styles don't apply to <a> links since they are inline elements by default.
  display: inline-block;
  padding: $margin-vertical-default 2em;
  // Address Firefox 4+ setting `line-height` on `input` using `!important` in
  // the UA stylesheet. And prevent its "line-height: normal" rule below from
  // breaking the "appearance: button" styling on the input elements.
  line-height: inherit;
  text-decoration: none;
  // Improve usability and consistency of cursor style between image-type `input` and others.
  cursor: pointer;
  color: color('button');
  background-color: color('button-bg');
  border: 0;
  font-family: $roboto-condensed;
  font-style: normal;
  font-weight: bold;
  font-size: (18rem / 16);
  margin: 1em 0;

  // Remove the inner border and padding in Firefox.
  &::-moz-focus-inner {
    border-style: none;
    padding: 0;
  }

  // Restore the focus styles unset by the previous rule.
  &:-moz-focusring {
    outline: 1px dotted ButtonText;
  }

  &:focus,
  &:hover,
  &:active {
    // Override any link underlines and color changes.
    text-decoration: none;
    color: color(button);
  }
}

[type='submit'] {
  width: 100%;
  @include respond-to('l') {
    width: auto;
  }
}

// Add button variations here.
// .button,
// %button {
//   &--variant-name {
//     // Add styles here.
//   }
// }

// "ghost" buttons or links with transparent background, and border
%button {
  &-hollow {
    @extend %button;
    // Webkit shows user agent gradient from .button if bg color transparent
    // background-color: transparent;
    background-color: color(button-hollow-bg);
    border: 1px solid color(button-hollow);
    color: color('button-hollow');
    transition: background-color .6s ease 0s, color .6s ease 0s;

    &:focus,
    &:hover,
    &:active {
      background-color: color(button-hollow);
      color: color('white');
    }
  }
}

// Choices js library for select element added functionality - removable button
%button-selecteditem {
  background-color: color('button-selecteditem');
  border-radius: 5em;
  color: color('white');
  font-family: inherit;
  font-size: (10rem / 16);
  font-weight: bold;
  line-height: 1.3;
  margin: 0 .4em 0 0;
  padding: .8em 1em .6em;
  text-transform: uppercase;
  white-space: nowrap;
}

%button-x {
  background-image: $x;
  background-size: 1em;
  font-size: inherit;
  margin: 0 0 0 1em;
  padding: .8em 1em .6em;
  vertical-align: baseline;
  width: 1em;
}

%button-selecteditem-withremove {
  @extend %button-selecteditem;
  padding: 0 1em;
}

// The disabled variation should always go last, so that it overrides any
// other variations.
%button--disabled[disabled] {
  background-color: color('button-disabled-bg');
  background-image: none;
  // Re-set default cursor for disabled elements.
  // cursor: default;
  cursor: not-allowed;
  color: color(button-disabled);

  &:focus,
  &:hover,
  &:active {
    // Override any link underlines and color changes.
    color: color(button-disabled);
  }
}
