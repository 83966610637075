// Keyframes for Animations
//
// These aren't actually animation properties in CSS.  
// They are just keyframe definitions that can be used within animations.
//
// **`Flash`** keyframes are used to animate a :target object
// **`FadeIn`** keyframes are used to appear a mobile navigation menu
//
// Style guide: sass.mixins.animations

// Flash 
//
// Used in :target
@keyframes flash {
  from,
  50%,
  to {
    opacity: 1;
  }

  25%,
  75% {
    opacity: 0;
  }
}

// FadeIn
//
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
