// Dependencies.
@import 'base/forms/forms';

// Labels hidden 
//
// The clean design of the min2go theme requires labels to be hidden
// or act as placeholders and then animated when the field is being used
// We need to keep them in HTML for accessibility (screen readers etc.)
//
// Markup: label-hidden.twig
//
// Style guide: forms.labels-hidden

.cijene-mailom {
  // make more room for "floated" hidden labels
  margin: 2*$margin-vertical-default 0 $margin-vertical-default;

  // make sure the font is identical to the placeholder
  label,
  input,
  select {
    display: block;
    font: normal 1rem/#{$input-line-height} $font-family-default;
    @include respond-to('l') {
      width: 12em;
    }
  }

  // labels must be put after the input in HTML for this to work! 
  // labels are positioned exactly over the placeholder text
  // labels are transparent at this point to avoid rounding errors
  label {
    color: inherit;
    display: block;
    font-style: italic;
    opacity: 0;
    position: absolute;
    text-align: left;
    // be careful about adding 0 (unitless) inside calc()
    transform: translateX(#{$input-padding-horizontal}) translateY(calc(-1*(#{$input-padding-vertical + $input-line-height + $input-margin-vertical} + #{$input-border-bottom-width})));
    transition: transform .3s ease 0s, opacity .3s ease 0s, color .3s ease 0s;
    z-index: 1;
    @include respond-to('l') {
      // be careful about adding 0 (unitless) inside calc()
      transform: translateX(#{$input-padding-horizontal-desktop}) translateY(calc(-1*(#{$input-padding-vertical-desktop} + #{$input-line-height}em + #{$input-border-bottom-width-desktop})));
    }

    &[for='vrstagoriva'] {
      display: none;
    }
  }

  input {
    box-shadow: none;
    z-index: 10;
    @include respond-to('l') {
      background-color: $input-bg-desktop;
      font-style: italic;
      margin: 0 1px 0 0;
    }

    &:focus,
    &:active {
      // when input is focused we animate the label away to top and left
      & + label {
        color: $input-noaction-color;
        opacity: 1;
        // be careful about adding 0 (unitless) inside calc()
        transform: translateX(0) translateY(calc(-1*(#{2*($input-padding-vertical + $input-line-height) + $input-margin-vertical} + #{$input-border-bottom-width})));
        @include respond-to('l') {
          // be careful about adding 0 (unitless) inside calc()
          transform: translateX(0) translateY(calc(-2*(#{$input-padding-vertical-desktop} + #{$input-line-height}em + #{$input-border-bottom-width-desktop})));
        }
      }
    }

    &[type='submit'] {
      font-style: normal;
      font-weight: bold;
      font-size: (18rem / 16);
      margin: 1em auto;
      @include respond-to('l') {
        background-color: color('button-bg');
        width: auto; // override from above
      }
    }
  }

  select {
    box-shadow: none;
    color: $input-noaction-color;
    // no idea why select presents hight 2px more compared to input
    // it makes desktop layout container too high so to correct that: 
    line-height: 1.3;
    margin: 0;
    @include respond-to('l') {
      background-color: $input-bg-desktop;
    }

    &:focus,
    &:active {
      color: inherit;
    }
  }

  & > .form-items {
    text-align: left;
    @include respond-to('l') {
      box-shadow: $form-item-boxshadow;
      display: flex;
      align-content: center;
      justify-content: center;
      align-items: flex-start;
      margin: 0 auto;
      width: max-content;
    }
  }

  .form-item {
    @include respond-to('l') {
      background-color: color('form-item-separator'); // #f2f3f4
    }
  }
}

